<template>
    <div class="card">
        <div class="bg-blue-light p-2">
            <TitleButton
                :showBtn="false"
                btnTitle="Add New"
                title="Receivable Register"
            />
            <div class="row match-height">
                <div class="col-12 col-lg-8 col-xl-9 col-xxl-7 mt-2">
                    <DateQuerySetter
                        @onClickGo="getDateWiseCashAndBank"
                    />
                </div>
            </div>
        </div>

        <div class="mt-2 px-2">
            <ReceivableTable
                :tableData="receivable"
            />
        </div>
        <GlobalLoader />
    </div>
</template>

<script setup>
import { onMounted } from 'vue';
import {useRoute} from 'vue-router'
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ReceivableTable from '@/components/molecule/company/report/ReceivableTable.vue'
import useReport from '@/services/modules/dashboard/report.js'
const {receivable, fetchReceivable} = useReport()
const route = useRoute()
onMounted(() => {
    fetchReceivable(getQuery ())
})
function getDateWiseCashAndBank () {
    fetchReceivable(getQuery ())
}
function getQuery () {
    let queryStr = '?company_id=' + route.params.companyId
    if(!route.query.start || !route.query.end) return queryStr
    return queryStr+`&receivable_date={
    "start": "${route.query.start}",
    "end": "${route.query.end}"
    }`
}
</script>